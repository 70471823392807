import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Overhead Squats 4×4\\@85%1RM`}</p>
    <p>{`then,`}</p>
    <p>{`500M Ski Erg Buy In,`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`7-Full Snatch (115/75)`}</p>
    <p>{`14-Burpee Box Jump Overs (24/20″)`}</p>
    <p>{`500M Row Cash Out.  For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Sunday (make up day) we will meet at Perrin Park (414 Perrin Lane,
Jeffersonville, IN 47130), weather permitting, for a fun outdoor wod on
the fitness loop at our normal class time of 12:30.  The park is 5 miles
from The Ville, just over 2nd street bridge about 4 miles down Utica
Pike.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      